const dashboardBlack = require('./icons_v2/dashboard_black.svg');
const dashboardWhite = require('./icons_v2/dashboard_white.svg');
const burgerMenu = require('./icons/burger_menu_color.svg');
const patientsBlack = require('./icons/patients_black.svg');
const patientsWhite = require('./icons/patients_white.svg');
const medicalTeamBlack = require('./icons/medical_team_black.svg');
const medicalTeamWhite = require('./icons/medical_team_white.svg');
const supportBlack = require('./icons/supportBlack.svg');
const supportWhite = require('./icons/supportWhite.svg');
const profileBlack = require('./icons/profile_black.svg');
const settingsBlack = require('./icons/setings_black.svg');
const hospitalBlack = require('./icons_v2/hospital_black.svg');
const hospitalWhite = require('./icons_v2/hospital_white.svg');
const mentalHealthWhite = require('./icons_v2/mentalHealthWhite.svg');
const mentalHealthBlack = require('./icons_v2/mentalHealthBlack.svg');
const mentalHealthWhiteAssign = require('./icons_v2/SAMT_white.svg');
const mentalHealthBlackAssign = require('./icons_v2/SAMT_black.svg');

const signOutBlack = require('./icons_v2/sign_out_black.svg');
const vitalCheckups = require('./icons_v2/logo_vital_checkups.svg');
const vitalCheckupsNoTitle = require('./icons_v2/vitalcheckups_no_title.svg');
const vitalCheckLogo = require('./pdfImages/vitalCheckLogo.png');
const allergies = require('./pdfImages/allergies-removebg-preview.png');
const calender = require('./pdfImages/calender-removebg-preview.png');
const email = require('./pdfImages/email-removebg-preview.png');
const height = require('./pdfImages/height-removebg-preview.png');
const homemed = require('./pdfImages/home_med-removebg-preview.png');
const location = require('./pdfImages/location-removebg-preview.png');
const perscription = require('./pdfImages/perscription-removebg-preview.png');
const doctorblack = require('./pdfImages/doctor_black.png');
const phone = require('./pdfImages/phone-removebg-preview.png');
const weight = require('./pdfImages/weight-removebg-preview.png');
const accountOutline = require('./icons_v2/account-outline.png');
const verifireBlack = require('./icons/verifireDark.svg');
const verifireWhite = require('./icons/verifireWhite.svg');

const seeMoreBlack = require('./icons_v2/see_more_black.svg');
const seeMoreWhite = require('./icons_v2/see_more_white.svg');

const burgerMenuPrimary = require('./icons_v2/burger_menu_color.svg');
const profileSettingsBlack = require('./icons_v2/profile_settings_black.svg');

const abnormalNotification = require('./icons/abnormal_notification_White.svg');
const allNotification = require('./icons/all_notifications_white.svg');
const missedNotificationWhite = require('./icons/missed_notification_white.svg');
const messagesNotificationWhite = require('./icons/messages_notification_white.svg');
const appointmentsWhite = require('./icons/appointments_white.svg');

const abnormalNotificationRed = require('./icons/abnormal_notification_Color.svg');
const missedNotificationColor = require('./icons/missed_notification_color.svg');
const messagesNotificationBlack = require('./icons/messages_notification_black.svg');
const appointmentsBlack = require('./icons/appointments_black.svg');

const pencilImg = require('./icons/edit_white.svg');
const attachWhite = require('./icons/attach_white.svg');
const videocallWhite = require('./icons_v2/videocall_white.svg');
const videocallBlack = require('./icons_v2/videocall_black.svg');

const glucoseMeterWhtie = require('./icons/glucose_meter_whtie.svg');
const cholesterolMeterWhite = require('./icons/cholesterol_meter_White.svg');
const oxymeterWhite = require('./icons/oxymeter_White.svg');
const scaleWhite = require('./icons/scale_white.svg');
const thermometerWhite = require('./icons/thermometer_White.svg');
const bloodPresureWhite = require('./icons/blood_presure_white.svg');
const sendButtonWhite = require('./icons/send_button_white.svg');
const devicesBlack = require('./icons/devices_black.svg');
const devicesWhite = require('./icons/devices_white.svg');
const monitoringPlanBlack = require('./icons/monitoring_plan_black.svg');
const doctorBlack = require('./icons/doctor_black.svg');
const doctorPrimary = require('./icons/doctor_primary.svg');
const doctorSecondary = require('./icons/doctor_secondary.svg');
const nurseBlack = require('./icons/nurse_black.svg');
const nurseSecondary = require('./icons/nurse_secondary.svg');
const patientBlack = require('./icons/patient_black.svg');
const patientWhite = require('./icons/patient_white.svg');
const patientSecondary = require('./icons/patient_secondary.svg');

const glucoseMeterPrimary = require('./icons/glucose_meter_primary.svg');
const cholesterolMeterPrimary = require('./icons/cholesterol_meter_primary.svg');
const oxymeterPrimary = require('./icons/oxymeter_primary.svg');
const scalePrimary = require('./icons/scale_primary.svg');
const thermometerPrimary = require('./icons/thermometer_primary.svg');
const bloodPressurePrimary = require('./icons/blood_pressure_primary.svg');

const bloodPresureBlack = require('./icons/blood_presure_black.svg');
const cholesterolMeterBlack = require('./icons/cholesterol_meter_black.svg');
const glucoseMeterBlack = require('./icons/glucose_meter_black.svg');
const oxymeterBlack = require('./icons/oxymeter_black.svg');
const scaleBlack = require('./icons/scale_black.svg');
const thermometerBlack = require('./icons/thermometer_black.svg');

const organizationsBlack = require('./icons_v2/organizations_black.svg');
const organizationsWhite = require('./icons_v2/organizations_white.svg');

const thermometerGrey = require('./icons/thermometer_grey.svg');
const bloodPressureGrey = require('./icons/blood_pressure_grey.svg');
const glucoseMeterGrey = require('./icons/glucose_meter_grey.svg');
const oxymeterGrey = require('./icons/oxymeter_grey.svg');
const scaleGrey = require('./icons/scale_grey.svg');
const cholesterolMeterGrey = require('./icons/cholesterol_meter_grey.svg');
const reminder = require('./icons_v2/reminder.svg');
/// devices

const billWhite = require('./icons_v2/invoice.svg');
const billBlack = require('./icons_v2/bill.svg');
const letterM = require('./icons_v2/layer1.svg');
const letterA = require('./icons_v2/letterA.svg');
const androidQrCode = require('./pdfImages/android_qr_code.png');

const iosQrCode = require('./pdfImages/ios_qr_code.png');
const spanishAppStore = require('./pdfImages/spanish_app_store.png');
const spanishPlayStore = require('./pdfImages/spanish_play_store.png');
const englishAppStore = require('./pdfImages/english_app_store.png');
const englishPlayStore = require('./pdfImages/english_play_store.png');

export { iosQrCode, androidQrCode, mentalHealthBlackAssign, letterM, letterA, mentalHealthWhiteAssign, spanishAppStore, englishAppStore, englishPlayStore, spanishPlayStore, mentalHealthWhite, mentalHealthBlack, verifireBlack, verifireWhite, supportWhite, supportBlack, reminder, vitalCheckLogo, allergies, doctorblack, calender, homemed, email, height, location, phone, weight, perscription, appointmentsBlack, accountOutline, bloodPressureGrey, messagesNotificationBlack, missedNotificationColor, organizationsBlack, patientWhite, organizationsWhite, seeMoreWhite, seeMoreBlack, vitalCheckups, burgerMenuPrimary, signOutBlack, hospitalBlack, hospitalWhite, profileSettingsBlack, medicalTeamWhite, patientsWhite, dashboardWhite, patientSecondary, patientBlack, nurseSecondary, nurseBlack, doctorSecondary, doctorPrimary, doctorBlack, thermometerGrey, glucoseMeterGrey, oxymeterGrey, scaleGrey, cholesterolMeterGrey, bloodPresureBlack, cholesterolMeterBlack, glucoseMeterBlack, oxymeterBlack, scaleBlack, thermometerBlack, thermometerPrimary, scalePrimary, glucoseMeterPrimary, cholesterolMeterPrimary, bloodPressurePrimary, oxymeterPrimary, monitoringPlanBlack, devicesBlack, devicesWhite, sendButtonWhite, glucoseMeterWhtie, cholesterolMeterWhite, oxymeterWhite, scaleWhite, thermometerWhite, bloodPresureWhite, dashboardBlack, burgerMenu, medicalTeamBlack, patientsBlack, settingsBlack, profileBlack, abnormalNotificationRed, abnormalNotification, allNotification, missedNotificationWhite, messagesNotificationWhite, appointmentsWhite, pencilImg, attachWhite, videocallWhite, billBlack, billWhite, videocallBlack, vitalCheckupsNoTitle };
