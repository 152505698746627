<!-- eslint-disable no-unused-expressions -->
<template>
	<div>
		<v-row class="mx-2">
			<v-col cols="12">
				<v-card flat style="border-radius: 12px" elevation="6">
					<v-card-title class="elevation-10">
						<v-row >
							<v-col class="d-flex align-center justify-start" cols="8">
								<h5 class="d-flex align-left">{{ $t("patients") }}</h5>
							</v-col>
							<v-col cols="4">
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								:label="$t('search-patient')"
								single-line
								hide-details
								filled
								dense
								background-color="#E8E8E8"
								rounded
								class="table-search-button"
							/>
							</v-col>
						</v-row>
					</v-card-title>
					<v-data-table
						:loading="loading"
						:headers="headers"
						:no-data-text="$t('no-data-available')"
						:loading-text="$t('loading')"
						:headers-length="headers.length"
						:search="search"
						:items="filteredUsers"
						:items-per-page="-1"
						sort-by="id"
						item-key="id"
						fixed-header
						dense
						class="elevation-15"
						style="cursor: pointer"
						@click:row="userDetails"
						:height="testHeight"
						:footer-props="{
							itemsPerPageText: this.$t('row'),
							itemsPerPageAllText: this.$t('all'),
						}"
						>
						<!-- <template v-slot:[footer.prepend="{}">
							<v-btn
							v-if="roleName === 'HOSPITAL_ADMIN'"
							class="table-create-button"
							label="Test"
							@click="dialog = true"
							>
							<span>{{ $t("addNewPatientTooltip") }}</span>
							</v-btn>
						</template> -->
						<template v-slot:[`footer.prepend`]>
							<v-btn
								v-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'"
								class="table-create-button"
								label="Test"
								@click="dialog = true"
							>
								<img class="pt-1" height="20px" width="20px" :src="patient_white" style="margin-right:7%; margin-bottom: 5%;"/>
								<span style="text-transform: none;">{{ $t("addNewPatientTooltip") }}</span>
							</v-btn>
						</template>
						<template v-slot:[`item.statusShortDescription`]="{ item }">
							<img class="my-1" :src="patient_icon(item.status)" height="24px" />
						</template>
						<template v-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR'" v-slot:[`item.email`]="{ item }">
							<v-row>
								<v-col cols="10">
									<h6 style="font-size:12px;">{{ item.email }}</h6>
								</v-col>
								<v-col cols="2">
									<v-tooltip top>
										<template v-slot:activator="{on}">
											<v-icon v-if="item.status !== 1" color="primary" @click.stop="openEmailEditDialog(item)" v-on="on">mdi-pencil</v-icon>
										</template>
										<span>{{$t("edit-email")}}</span>
									</v-tooltip>
								</v-col>
							</v-row>
						</template>
						<template v-slot:[`item.actionVideoCall`]="{ item }">
							<div v-if=" roleName === 'DOCTOR' || roleName === 'NURSE'">
								<v-btn class="mx-1 my-1" v-if="item.isPatientAvailableForVideoCall && item.status !== 0" elevation="0" fab small style="height: 30px; width: 30px" color="secondary" @click.stop="initiateCall(item, true)">
								<img height="18px" width="18px" :src="videocallBlack" />
								</v-btn>
								<v-btn class="mx-1 my-1" v-if="item.isPatientAvailableForVideoCall && item.status !== 0" elevation="0" fab small style="height: 30px; width: 30px" color="secondary" @click.stop="initiateCall(item, false)">
								<v-icon color="primary" >mdi-phone</v-icon>
								</v-btn>
							</div>
						</template>
<!--            <template v-slot:[`item.patientHasDoneReadingForTodayValue`]="{ item }">-->
<!--              <span :class="item.patientHasDoneReadingForTodayValue === 'Yes' ? 'mdi mdi-checkbox-marked' : 'mdi mdi-checkbox-blank-outline'"-->
<!--              :style="{ color: item.patientHasDoneReadingForTodayValue === 'Yes' ? '#6600FF' : '#6600FF' }" style="font-size:24px; margin-left:10px;">-->
<!--             </span>-->
<!--            </template>-->
						<template v-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'" v-slot:[`item.action`]="{ item }">
							<div v-if="item.status !== 0" :disabled="!item.hasMedicalTeam" label @click.stop="sendActivationEmail(item)">
								<!--            Send activation email-->
								<div v-if="item.status !== 1">
								<v-tooltip top>
									<template v-slot:activator="{on}">
									<v-icon class="float-right" color="primary" size="27" v-on="on">mdi-email</v-icon>
									</template>
									<span>{{$t('send-activation-email')}}</span>
								</v-tooltip>
								</div>
								<div v-else>
								<v-tooltip top>
									<template v-slot:activator="{on}">
									<v-icon class="float-right" color="primary" size="27" v-on="on">mdi mdi-lock-reset</v-icon>
									</template>
									<span>{{$t('send-reset-email')}}</span>
								</v-tooltip>
								</div>

								<!--            Reset Password Email-->
								<!--              <v-tooltip bottom>-->
								<!--                  <v-icon v-else color="primary" size="27">mdi mdi-lock-reset</v-icon>-->
								<!--                <span>test</span>-->
								<!--              </v-tooltip>-->
							</div>
						</template>
						<template v-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'" v-slot:[`item.userStatus`]="{ item }">
							<v-switch
								class="mt-n1"
								hide-details
										:disabled="item.status === -1"
										v-model="item.status"
										@click.stop="setUserStatus(item)">
							</v-switch>
						</template>
					</v-data-table>
				</v-card>
				<AddPatientDialog
					v-if="dialog"
					:dialog="dialog"
					@false="closeDialog()"
					@close="success"
				></AddPatientDialog>
				<EmailEditDialog
					v-if="emailEditDialog"
					:dialog="emailEditDialog"
					:patient="patientForEdit"
					@close="closeEditEmailDialog"
				></EmailEditDialog>
			</v-col>
		</v-row>
	</div>
  </template>

<script>
import { mapGetters, mapState } from 'vuex';
import AddPatientDialog from '@/views/dialogs/PatientDialog.vue';
import EmailEditDialog from '@/views/dialogs/EmailEditDialog.vue';
import { patientSecondary, patientBlack, videocallBlack, patientWhite } from '@/assets';
import { AsYouType } from 'libphonenumber-js';
import { permissions } from '@/utils/calls';

export default {
	components: {
		AddPatientDialog,
		EmailEditDialog,
	},
	data () {
		return {
		// userStatus: true,
			search: '',
			showEmailEditDialog: false,
			emailEditDialog: false,
			loading: true,
			dialog: false,
			filteredUsers: [],
			headerClass: 'black_inner--text !important;',
			patient_secondary: patientSecondary,
			patient_black: patientBlack,
			videocallBlack: videocallBlack,
			patient_white: patientWhite,
			patientForEdit: '',
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			usersFromRepo: (state) => state.users.users,
			patientsFromRepo: (state) => state.hospitals.patients,
			relatedPatients: (state) => state.hospitals.relatedPatients,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
			myMedicalTeam: 'authentication/getMyMedicalTeam',
			pageHeight: 'pageHeight',
			delay: 'authentication/getDelay',
		}),
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return window.innerHeight - 320;
			case 'sm':
				return window.innerHeight - 300;
			case 'md':
				return window.innerHeight - 300;
			case 'lg':
				return window.innerHeight - 280;
			case 'xl':
				return window.innerHeight - 280;
			default:
				return 0;
			}
		},
		headers () {
			var headers = [];
			headers = [
				{
					text: 'USER',
					value: 'statusShortDescription',
					class: this.headerClass,
				},
				{
					text: this.$t('header-full-name'),
					value: 'fullname',
					class: this.headerClass,
				},
				{
					text: this.$t('header-sex'),
					value: 'sex',
					class: this.headerClass,
				},
				{ text: this.$t('header-age'), value: 'age', class: this.headerClass },
				{
					text: this.$t('header-phone'),
					value: 'phone',
					class: this.headerClass,
				},
				{
					text: this.$t('header-email'),
					value: 'email',
					class: this.headerClass,
				},
				// {
				// 	text: this.$t('header-patient-has-done-reading-today'),
				// 	value: 'patientHasDoneReadingForTodayValue',
				// 	class: this.headerClass,
				// },
				// {
				// 	text: this.$t('header-medical-no'),
				// 	value: 'patientMetadata.medicalRegistrationNumber',
				// 	class: this.headerClass,
				// },
				{
					text: '',
					value: 'actionVideoCall',
					class: this.headerClass,
				},
				{
					text: '',
					value: 'action',
					class: this.headerClass,
				},
			];
			if (this.roleName === 'HOSPITAL_ADMIN') {
				headers.pop();
			}
			if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				headers.push({
					text: this.$t('status'),
					value: 'userStatus',
					class: this.headerClass,
				});
			}
			return headers;
		},
	},
	methods: {
		openEmailEditDialog (item) {
			this.patientForEdit = item;
			this.emailEditDialog = true;
		},
		async setUserStatus (item) {
			const requestData = { userId: item.id, statusValueToSet: !item.status ? 0 : 1 };

			try {
				const res = await this.$store.dispatch('users/setUserStatus', requestData);
				if (res.resFlag) {
					this.filteredUsers = this.filteredUsers.map(user => {
						if (user.id === res.data.id) {
							// Change the status of the desired user
							return { ...user, status: res.data.status };
							// eslint-disable-next-line no-prototype-builtins
						} else if (user.hasOwnProperty('status')) {
							// Ensure the 'status' property exists before returning the user object
							return user;
						}
					});
					this.$store.commit('alerts/add', {
						id: 'addedAdmin',
						type: 'success',
						color: 'main_green',
						// message: res.msg,
						message: this.$t('success'),
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				} else {
					this.$store.commit('alerts/add', {
						id: 'addedAdmin',
						type: 'error',
						color: 'main_red',
						// message: res.msg,
						message: this.$t('failed'),
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				}
			} catch (error) {
				this.$store.commit('alerts/add', {
					id: 'addedAdmin',
					type: 'error',
					color: 'main_red',
					// message: error,
					message: this.$t('failed'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
		calculateAge (dateString) {
			var today = new Date();
			var birthDate = new Date(dateString?.split('T')[0]);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
		userDetails (item) {
			switch (item.status) {
			// case -1:
			// 	this.$store.commit('alerts/add', {
			// 		id: 'addedOrganization',
			// 		type: 'error',
			// 		color: 'main_red',
			// 		message: this.$t('userIsInPendingState'),
			// 	});
			// 	this.$store.dispatch('alerts/clearWithDelay', this.delay);
			// 	break;
			case 0:
				this.$store.commit('alerts/add', {
					id: 'addedOrganization',
					type: 'error',
					color: 'main_red',
					message: this.$t('userIsInActiveState'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
				break;
			default:
				if (!item.hasMedicalTeam) {
					this.$store.commit('alerts/add', {
						id: 'addedOrganization',
						type: 'error',
						color: 'main_red',
						message: this.$t('medicalTeamCheck'),
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				} else {
					this.$router.push({
						name: 'Patient Details',
						params: { id: `${item.id}` },
					});
				}
				break;
			}
		},
		async getPatients () {
			const hospitalIdFromStore = this.userData.hospitalAssignedData.id;
			if ((this.roleName === 'ORGANIZATION_ADMIN' || this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') && hospitalIdFromStore !== undefined) {
				// await this.$store.dispatch('hospitals/getRelatedPatientsv2', hospitalIdFromStore)
				await this.$store.dispatch('hospitals/getRelatedPatientsV3', hospitalIdFromStore)
					.then(() => {
						this.filteredUsers = this.relatedPatients;
						// eslint-disable-next-line no-unused-expressions
						this.filteredUsers?.forEach((user) => {
							user.fullname = user.firstName + ' ' + user.lastName;
							user.age = this.calculateAge(user.birthdate);
							user.sex = user.gender === 'M' ? this.$t('male') : this.$t('female');
							user.phone = new AsYouType().input(user.phone);
							user.patientHasDoneReadingForTodayValue = user.patientHasDoneReadingForToday === true ? this.$t('yes') : this.$t('no');
							this.verifiersFromRepo.forEach(v => {
								if (user.status === v.lowValue) {
									user.statusShortDescription = v.shortDescription;
									user.statusLongDescription = v.longDescription;
								}
							});
						});
						this.loading = false;
					});
			} else if ((this.roleName === 'ORGANIZATION_ADMIN' || this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') && hospitalIdFromStore === undefined) {
				this.filteredUsers = [];
				this.loading = false;
			}
		},
		async getStaff () {
			const hospitalId = this.userData.hospitalAssignedData.id;
			await this.$store
				.dispatch('hospitals/getRelatedMedicalStaff', hospitalId)
				.then(() => {
					// this.loading = false;
				});
		},
		async getVerifiers () {
			const header = { domainsList: 'USER_STATUS,HEALTH_INSURANCE_PROGRAM' };
			await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header);
		},
		async getTimeZones () {
			await this.$store.dispatch('verifiers/getTimeZones');
		},
		closeEditEmailDialog (dataFromEditMailDialog) {
			this.emailEditDialog = false;
			if (dataFromEditMailDialog === null) return;
			this.sendActivationEmail(dataFromEditMailDialog);
		},
		closeDialog () {
			this.dialog = false;
			this.getPatients();
		},
		editEmail (item) {
		// Perform any necessary actions here
		// Open the email edit dialog
			this.showEmailEditDialog = true;
		},
		success (res) {
			this.dialog = false;
			this.getPatients();
			if (res.resFlag) {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'success',
					color: 'main_green',
					// message: res.msg,
					message: this.$t('success'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'error',
					color: 'main_red',
					// message: res.msg,
					message: this.$t('failed'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
		patient_icon (status) {
			switch (status) {
			case 1:
				return this.patient_black;
			default:
				return this.patient_secondary;
			}
		},
		async sendActivationEmail (item) {
			const sendValidationEmail = { email: item.email };
			await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail)
				.then(res => {
					if (res.resFlag) {
						this.$store.commit('alerts/add', {
							id: 'addedAdmin',
							type: 'success',
							color: 'main_green',
							// message: res.msg,
							message: this.$t('success'),
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					} else {
						this.$store.commit('alerts/add', {
							id: 'errorAddingAdmin',
							type: 'error',
							color: 'main_red',
							// message: res.msg,
							message: this.$t('failed'),
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					}
				});
		},
		async initiateCall (item, videotrack) {
			if (await permissions()) return;
			const type = videotrack ? 'video' : 'audio';
			const body = {
				UserId: item.id,
				MessageToSend: `${item.firstName} ${item.lastName} has started ${type} call`,
				NeedVideoTrack: videotrack,
			};
			await this.$store.dispatch('notifications/initVideoCall', body).then(async (res) => {
				this.loading = true;
				await this.goToRoom(res.data.appointmentData, videotrack);
				this.loading = false;
			});
		},
		async goToRoom (item, videotrack) {
			this.$router.push({
				name: 'VideoRoom',
				params: {
					id: `${item.videoRoomId}`,
					userId: `${item.patientId}`,
					appointmentId: item.appointmentId ? item.appointmentId : item.id,
					token: localStorage.sessionToken,
					needVideoRecording: item.needVideoRecording,
					videotrack: videotrack,
				},
			});
		},
	},
	async mounted () {
		await this.getVerifiers();
		await this.getTimeZones();
		await this.getPatients();
		await this.getStaff();
	},
};
</script>
