<template>
  <v-dialog v-if="dialog" v-model="dialog" width="600" persistent>
    <v-card flat style="overflow: auto">
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title">{{ $t("edit-email-dialog") }} {{ patient.fullname }}</span>
        <v-spacer />
        <v-icon small color="primary" @click="$emit('close', null)">
          mdi-window-close
        </v-icon>
      </v-card-title>
      <v-card-text class="mt-1">{{ $t('edit-email-addres-text') }}</v-card-text>
      <v-card-actions>
        <v-text-field
            v-model="patienetEmail"
            :label="this.$t('email')"
			@input="handleInputChange"
            prepend-inner-icon="mdi-account mr-4"
            dense
            background-color="#E8E8E8"
            rounded
			hide-details
            outlined
			type="email"
            style="border-radius: 12px !important;"
        ></v-text-field>
      </v-card-actions>
      <v-card-actions class="d-flex justify-content-end">
		<div style="text-align:center">
			<v-btn class="table-cancel-button" @click="$emit('close', null)">
            <span color="black--text">{{ $t("cancel") }}</span>
          </v-btn>
          <v-btn
            class="table-create-button"
            @click="editInvalidEmail"
			:disabled = "saveButtonDisabled">
            <span>{{ $t("save") }}</span>
          </v-btn>
          <!-- <v-progress-circular indeterminate color="primary" v-if="loading" /> -->
		</div>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>
<script>
import { mapState } from 'vuex';

export default {
	components: {},
	data () {
		return {
			patienetEmail: '',
			saveButtonDisabled: true,
		};
	},
	props: ['dialog', 'patient'],
	computed: {
		...mapState({
		}),
	},
	mounted () {
		this.patienetEmail = this.patient.email;
	},
	methods: {
		handleInputChange () {
			this.saveButtonDisabled = false;
		},
		//   Make a function to send the email.
		editInvalidEmail () {
			const body = {
				userId: this.patient.id,
				newEmailValue: this.patienetEmail,
			};
			this.$store.dispatch('users/editInvalidEmail', body).then(async (res) => {
				if (res.data.resFlag) {
					this.$store.commit('alerts/add', {
						id: 'newUser',
						type: 'success',
						message: 'Patient email edited!',

					});
					this.$store.commit('alerts/clear', {
						id: 'newUser',
						type: 'success',
						message: 'User email edited!',

					});
					this.patient.email = this.patienetEmail;
					this.$emit('close', res.data.data);
				} else {
					this.$store.commit('alerts/add', {
						id: 'newUser',
						type: 'error',
						message: res.data.msg,
					});
					this.$store.commit('alerts/clear', {
						id: 'newUser',
						type: 'error',
						message: res.data.msg,

					});
					this.$emit('close', res.data.data);
				}
			});
		},
	},
};
</script>
<style scoped>
.v-card__actions{
    padding: 8px;
    justify-content: end;
}
</style>
