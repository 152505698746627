<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            ref="textfield"
          v-model="textValue"
          :label="$t('birthdate')"
          prepend-inner-icon="mdi-calendar mr-4"
          readonly
          outlined
          style="border-radius: 12px !important; height: 40px"
          dense
          background-color="#E8E8E8"
          rounded
          @keydown.arrow-up.prevent="onArrowUp"
          @keydown.arrow-down.prevent="onArrowDown"
          @keydown.arrow-left.prevent="onArrowLeft"
          @keydown.arrow-right.prevent="onArrowRight"
                @keydown.enter.prevent="onEnter"
            v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="textValue"
        no-title
        :active-picker.sync="activePicker"
        :max="
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        "
        min="1900-01-01"
        @change="save"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
	props: {
		inputValue: String,
	},
	data: () => ({
		activePicker: null,
		date: null,
		menu: false,
	}),
	watch: {
		menu (val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'));
		},
		// activePicker () {
		// 	this.onArrowUp();
		// 	this.onArrowDown();
		// 	this.onArrowLeft();
		// 	this.onArrowRight();
		// },
	},
	computed: {
		textValue: {
			get () {
				return this.inputValue;
			},
			set (newValue) {
				this.$emit('update:inputValue', newValue);
			},
		},
	},
	methods: {
		save (date) {
			this.$refs.menu.save(date);
		},
		onEnter () {
			if (this.activePicker === 'YEAR') {
				this.activePicker = 'MONTH';
			} else if (this.activePicker === 'MONTH') {
				this.activePicker = 'DATE';
			} else {
				this.$refs.menu.save(this.textValue);
			}
			this.$refs.textfield.focus();
		},

		onArrowUp () {
			this.date = new Date(this.textValue);
			if (this.activePicker === 'YEAR') {
				this.date.setFullYear(this.date.getFullYear() + 1);
			} else if (this.activePicker === 'MONTH') {
				this.date.setMonth(this.date.getMonth() - 3);
			} else {
				this.date.setDate(this.date.getDate() - 7);
			}
			this.textValue = this.date.toISOString().substr(0, 10);
			this.$refs.textfield.focus();
		},
		onArrowDown () {
			this.date = new Date(this.textValue);
			if (this.activePicker === 'YEAR') {
				this.date.setFullYear(this.date.getFullYear() - 1);
			} else if (this.activePicker === 'MONTH') {
				this.date.setMonth(this.date.getMonth() + 3);
			} else {
				this.date.setDate(this.date.getDate() + 7);
			}
			this.textValue = this.date.toISOString().substr(0, 10);
			this.$refs.textfield.focus();
		},
		onArrowLeft () {
			if (this.activePicker === 'MONTH') {
				this.date = new Date(this.textValue);
				this.date.setMonth(this.date.getMonth() - 1);
				this.textValue = this.date.toISOString().substr(0, 10);
			} else if (this.activePicker !== 'YEAR' && this.activePicker !== 'MONTH') {
				this.date = new Date(this.textValue);
				this.date.setDate(this.date.getDate() - 1);
				this.textValue = this.date.toISOString().substr(0, 10);
			}
			this.$refs.textfield.focus();
		},
		onArrowRight () {
			if (this.activePicker === 'MONTH') {
				this.date = new Date(this.textValue);
				this.date.setMonth(this.date.getMonth() + 1);
				this.textValue = this.date.toISOString().substr(0, 10);
			} else if (this.activePicker !== 'YEAR' && this.activePicker !== 'MONTH') {
				this.date = new Date(this.textValue);
				this.date.setDate(this.date.getDate() + 1);
				this.textValue = this.date.toISOString().substr(0, 10);
			}
			this.$refs.textfield.focus();
		},
	},
};
</script>
